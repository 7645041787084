import {Routes as Switch, Route} from 'react-router-dom';
import Guichet_Page from './guichet_page';
import ErrorPage from './failed_page';
import SuccessPage from './success_page';
import ExpiredSessionPage from './failed_page_expired_session';
import ErrorProbemePage from './failed_page_probleme';

function Main(){
    return (
        <div>
            <Switch>
                 {/* <Route path="/" element={<Guichet_Page />} /> */}
                 <Route path="/:token" element={<Guichet_Page />} />
                 <Route path="/failed/:checkoutId" element={<ErrorPage />} />
                 <Route path="/success/:checkoutId" element={<SuccessPage />} />
                 <Route path="/transaction/failed-session-expired" element={<ExpiredSessionPage />} />
                 <Route path="/transaction/failed-transaction" element={<ErrorProbemePage />} />
            </Switch>        
        </div>
    )
}

export default Main;