import errorImage from '../src/media/error.png';
function ExpiredSessionPage(){
    return (
      
   <div class="container-fluid">
        <div class="row justify-content-center">
            <div class=" col-lg-6 col-md-8">
                <div class="card p-3">
                    <section id="main-content" class="container">
                        <div class="row-wrapper-x">
                           <section class="wpb_row  ">
                              <div class="wpb_column vc_column_container vc_col-sm-12">
                                 <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                       <div  class="wpb_single_image wpb_content_element text-center">
                                        <img src={errorImage} style={{"width":"200px"}} alt="" />
                                         <h2 class="text-danger">Echec du paiement</h2>
                                         <p>La session à expirée, Veuillez ré-essayer s'il vous plait.</p>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </section>
                        </div>
                     </section>
                </div>
            </div>
        </div>
    </div>
    )
}

export default ExpiredSessionPage;